import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content"]

  connect() {
    // Add ARIA attributes for accessibility
    this.element.setAttribute("role", "tooltip")
    this.contentTarget.setAttribute("role", "tooltip")
    this.contentTarget.setAttribute("aria-hidden", "true")
  }

  show() {
    this.contentTarget.classList.remove("invisible")
    this.contentTarget.setAttribute("aria-hidden", "false")
  }

  hide() {
    this.contentTarget.classList.add("invisible")
    this.contentTarget.setAttribute("aria-hidden", "true")
  }

  // Show tooltip on mouse enter or focus
  mouseEnter() {
    this.show()
  }

  // Hide tooltip on mouse leave or blur
  mouseLeave() {
    this.hide()
  }

  // Handle keyboard events
  keydown(event) {
    if (event.key === "Escape") {
      this.hide()
    }
  }
} 