import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "click", "anchor" ]

  click() {
    this.clickTargets.forEach(target => target.click())
  }

  encode({ target: { name, value } }) {
    for (const anchor of this.clickTargets) {
      // Get existing params from current URL
      const params = new URLSearchParams(anchor.search)
      // Add or update the new parameter
      params.set(name, value)
      // Set the updated search string
      anchor.search = params.toString()
    }
  }
}
