import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper'
import { Navigation, Pagination, Scrollbar, Keyboard } from 'swiper/modules'

export default class extends Controller {
  connect() {
    console.log("Carousel controller connected")
    
    this.swiper = new Swiper(this.element.querySelector('.swiper'), {
      modules: [Navigation, Pagination, Scrollbar, Keyboard],
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        type: 'fraction'
      },
      scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true
      },
      keyboard: {
        enabled: true,
        onlyInViewport: true
      }
    })
  }

  disconnect() {
    if (this.swiper) {
      this.swiper.destroy()
    }
  }
} 